@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500&display=swap');


/* body{
    font-family: 'Black Ops One', cursive;
} */

.head{
    font-family: 'Black Ops One', cursive;
}

.font1{
    font-family: 'Open Sans', sans-serif;
}

/* .font{
    font-family: 'Black Ops One', cursive;
} */


.imgbg{
    background-image: url("./images/bg.jpg");
    background-size: cover;
    opacity: 0.9;
}